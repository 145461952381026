<!--
  主页中添加组件弹框
-->
<!-- width="600px"-->
<template>
  <el-dialog
    :title="dialogTitleName"
    :visible="isShowComPersonAddAssembly"
    :before-close="handleClose"
    :close-on-click-modal="false"
    width="752px"
  >
    <!-- {{ objectId }} -->
    <!-- 默认的tab切换 -->
    <el-tabs
      v-model="activeName"
      v-if="
        !isEditToThis &&
        !isDashboardEditToThis &&
        !isFixedComponents &&
        !isDashboardPagEditing
      "
    >
      <!-- 单个仪表板组件选择 -->
      <el-tab-pane name="first" v-loading="isLoadingDashboardListStatus">
        <span slot="label">
          <el-radio v-model="radio" label="1" ref="defaultSelectDashboard">
            <!-- 仪表盘组件 -->
            {{ $t("vue_label_homepage_dashboard_components") }}
          </el-radio>
        </span>
        <el-form
          :model="DashboardCpnruleForm"
          :rules="DashboardCpnrules"
          ref="DashboardruleForm"
          label-width="140px"
          class="Dashboard-ruleForm"
        >
          <!-- 组件名称 -->
          <el-form-item
            :label="$t('homepage.components.name')"
            prop="DashboardName"
          >
            <el-input v-model="DashboardCpnruleForm.DashboardName"></el-input>
          </el-form-item>
          <!-- 选择仪表板 -->
          <el-form-item
            :label="$t('label.report.action.addtodashboard3')"
            prop="DashboardChooseID"
          >
            <!-- 请选择仪表板 -->
            <el-select
              v-model="DashboardCpnruleForm.DashboardChooseID"
              filterable
              reserve-keyword
              :placeholder="$t('label.select.dashboar.type')"
              @click.native="getDashboardList"
              @change="
                getDashboardList();
                clearDashboardruleForm();
              "
            >
              <el-option
                v-for="item in DashboardList"
                :key="item.id"
                reserve-keyword
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- 选择组件 -->
          <el-form-item
            :label="$t('vue_label_homepage_select_components')"
            prop="DashboardassemblyID"
          >
            <!-- 请选择组件 -->
            <el-select
              v-model="DashboardCpnruleForm.DashboardassemblyID"
              filterable
              reserve-keyword
              :placeholder="$t('vue_label_homepage_components')"
              @click.native="getAssemblyList"
              @change="getAssemblyList"
            >
              <el-option
                v-for="item in assemblyList"
                :key="item.id"
                reserve-keyword
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <!-- 添加仪表板面板 -->
      <el-tab-pane name="fourth">
        <span slot="label">
          <el-radio v-model="radio" label="5" ref="defaultSelectDashboard">
            <!-- 仪表盘面板 -->
            {{ $t("vue_label_homepage_kanban") }}
          </el-radio>
        </span>
        <el-form
          :model="DashboardruleForm"
          :rules="Dashboardrules"
          ref="instrumentForm"
          label-width="140px"
          class="Dashboard-ruleForm"
        >
          <!-- 组件名称 -->
          <el-form-item
            :label="$t('homepage.components.name')"
            prop="DashboardName"
          >
            <el-input v-model="DashboardruleForm.DashboardName"></el-input>
          </el-form-item>
          <!-- 选择仪表板 -->
          <el-form-item
            :label="$t('label.report.action.addtodashboard3')"
            prop="DashboardChooseID"
          >
            <!-- 请选择仪表板 -->
            <el-select
              v-model="DashboardruleForm.DashboardChooseID"
              filterable
              reserve-keyword
              :placeholder="$t('label.select.dashboar.type')"
              @click.native="getDashboardList"
              @change="
                getDashboardList();
                clearDashboardruleForm();
              "
            >
              <el-option
                v-for="item in DashboardList"
                :key="item.id"
                reserve-keyword
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <!-- 卡片视图选项面板 -->
      <el-tab-pane name="second" v-loading="isLoadingCardListStatus">
        <span slot="label">
          <el-radio
            v-model="radio"
            label="2"
            @click.native="clickObject"
            ref="radio2"
          >
            <!-- 卡片视图 -->
            {{ $t("vue_label_homepage_card_view") }}
          </el-radio>
        </span>
        <el-form
          :model="CardruleForm"
          :rules="Cardrules"
          ref="CardruleForm"
          label-width="140px"
          class="card-ruleForm"
        >
          <!-- 组件名称 -->
          <el-form-item :label="$t('homepage.components.name')" prop="CardName">
            <!-- 请输入组件名称 -->
            <el-input
              v-model="CardruleForm.CardName"
              :placeholder="$t('vue_label_dashboard_componentname')"
            ></el-input>
          </el-form-item>
          <!-- 选择对象 -->
          <el-form-item
            :label="$t('label.selectobject')"
            prop="CardObject"
            @click.native="clickObject"
          >
            <!-- 请输入选择对象 -->
            <el-select
              v-model="CardruleForm.CardObject"
              filterable
              :placeholder="$t('vue_label_notice_pleaseselectobject')"
              @change="clearSelectData"
            >
              <el-option
                v-for="item in selectObjects"
                :key="item.id"
                :label="item.labelname"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- 选择视图 -->
          <el-form-item
            :label="$t('vue_label_homepage_select_view')"
            prop="CardView"
          >
            <!-- 请选择视图 -->
            <el-select
              v-model="CardruleForm.CardView"
              filterable
              reserve-keyword
              :placeholder="$t('vue_label_notice_pleaseselecview')"
              @click="clickView"
            >
              <el-option
                v-for="item in selectView"
                :key="item.id"
                reserve-keyword
                :value="item.id"
                :label="item.label"
                class="viewInput"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- 选择展示字段 -->
          <el-form-item
            :label="$t('vue_label_homepage_select_display_field')"
            prop="CardField"
            class="chooseFiled"
          >
            <!-- 选择展示字段 -->
            <el-select
              v-for="(item, index) in fieldList"
              :key="index"
              v-model="CardruleForm.CardField"
              filterable
              :placeholder="$t('vue_label_homepage_select_display_field')"
              multiple
              :multiple-limit="4"
            >
              <!-- :disabled="
                  fieldList.some((item) => item.fieldId === childitem.id)
                " -->
              <el-option
                v-for="(childitem, childIndex) in selectfield"
                :key="childIndex"
                :value="childitem.id"
                :label="childitem.labelName"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- 选择按钮操作项 -->
          <el-form-item
            :label="$t('vue_label_homepage_select_button_operation')"
            class="chooseeditbtn"
          >
            <!--请选择按钮操作项  -->
            <el-select
              v-model="CardruleForm.CardChoosebtn"
              :placeholder="$t('vue_label_homepage_operationitem')"
              multiple
              :multiple-limit="2"
            >
              <template v-if="isShowBun">
                <el-option
                  v-for="item in operationSelection"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
                  min-width="300"
                >
                  <div class="dropDownBox">
                    <svg class="icon" aria-hidden="true">
                      <use :href="'#icon-' + item.svg"></use>
                    </svg>
                    <span>{{ item.name }}</span>
                  </div>
                </el-option>
                <el-option
                  v-for="item in operationSelections"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
                  min-width="300"
                >
                  <div class="dropDownBox">
                    <svg class="icon" aria-hidden="true">
                      <use :href="'#icon-' + item.svg"></use>
                    </svg>
                    <span>{{ item.name }}</span>
                  </div>
                </el-option>
              </template>
            </el-select>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <!-- <el-tab-pane name="third">
        <span slot="label">
          <el-radio v-model="radio" label="3">嵌入页面</el-radio>
        </span>

        <el-form
          :model="ViewruleForm"
          :rules="Viewrules"
          ref="ViewruleForm"
          label-width="120px"
          class="page-ruleForm"
        >
          <el-form-item label="组件名称" prop="ViewName">
            <el-input v-model="ViewruleForm.ViewName"></el-input>
          </el-form-item>
          <el-form-item label="描述">
            <el-input v-model="ViewruleForm.ViewDesc"></el-input>
          </el-form-item>
          <el-form-item label="URL地址" prop="ViewURL">
            <el-input v-model="ViewruleForm.ViewURL" type="url"></el-input>
          </el-form-item>
          <el-form-item label="高度" prop="ViewHeight">
            <el-input v-model="ViewruleForm.viewHeight" type="url"></el-input>
          </el-form-item>
        </el-form>
      </el-tab-pane> -->
      <!-- 添加固定组件 -->
      <el-tab-pane name="fixed">
        <span slot="label">
          <el-radio v-model="radio" label="4">
            <!-- 添加固定组件 -->
            {{ $t("vue_label_homepage_fixedcomponents") }}
          </el-radio>
        </span>
        <el-form
          :model="fixedForm"
          :rules="fixedFormRules"
          ref="fixedComponentsRef"
          label-width="140px"
          class="Dashboard-ruleForm"
        >
          <!-- 选择组件 -->
          <el-form-item
            :label="$t('vue_label_homepage_select_components')"
            prop="fixedCpnName"
          >
            <!-- 请选择组件 -->
            <el-select
              v-model="fixedForm.fixedCpnName"
              filterable
              reserve-keyword
              :placeholder="$t('vue_label_homepage_components')"
              @change="clickFixed"
            >
              <el-option
                v-for="(item, index) in selectFixedField"
                :key="index"
                reserve-keyword
                :value="item.id"
                :label="item.name"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <!-- 添加图表 -->
      <el-tab-pane name="chart">
        <span slot="label">
          <el-radio v-model="radio" label="6">
            <!-- 添加图表组件 -->
            <!-- 图表 -->
            {{ $t("vue_label_commonobjects_view_chart") }}
          </el-radio>
        </span>
      </el-tab-pane>
      <!-- 添加自定义组件 -->
      <el-tab-pane name="custom">
        <span slot="label">
          <el-radio v-model="radio" label="7">
            <!-- 自定义布局 -->
            {{ $t("homepage.layout.custom") }}
          </el-radio>
        </span>
      </el-tab-pane>
    </el-tabs>
    <!-- 以下都是编辑状态的tab切换的每个选项 -->
    <!-- 卡片视图选项面板 -->
    <div class="card-view-bar" v-if="isEditToThis" v-loading="isLoadingEdit">
      <el-form
        :model="CardruleForm"
        :rules="Cardrules"
        ref="CardruleForm"
        label-width="140px"
        class="card-ruleForm"
      >
        <!-- 组件名称 -->
        <el-form-item :label="$t('homepage.components.name')" prop="CardName">
          <!--  -->
          <el-input
            v-model="CardruleForm.CardName"
            :placeholder="$t('vue_label_dashboard_componentname')"
          ></el-input>
        </el-form-item>
        <!-- 选择对象 -->
        <el-form-item :label="$t('label.selectobject')" prop="CardObject">
          <!--请输入选择对象  -->
          <el-select
            v-model="CardruleForm.CardObject"
            filterable
            :placeholder="$t('vue_label_notice_pleaseselectobject')"
            @change="clearSelectData"
          >
            <el-option
              v-for="item in objectData"
              :key="item.id"
              :label="item.labelname"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- 选择=视图 -->
        <el-form-item
          :label="$t('vue_label_homepage_select_view')"
          prop="CardView"
        >
          <!-- 请选择选择视图 -->
          <el-select
            v-model="CardruleForm.CardView"
            filterable
            reserve-keyword
            :placeholder="$t('vue_label_notice_pleaseselecview')"
            @click="clickView"
          >
            <el-option
              v-for="item in selectView"
              :key="item.id"
              reserve-keyword
              :value="item.id"
              :label="item.label"
              class="viewInput"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- 选择展示字段 -->
        <el-form-item
          :label="$t('vue_label_homepage_select_display_field')"
          prop="CardField"
          class="chooseFiled"
        >
          <!-- 选择展示字段 -->
          <el-select
            v-for="(item, index) in fieldList"
            :key="index"
            v-model="CardruleForm.CardField"
            filterable
            :placeholder="$t('vue_label_homepage_select_display_field')"
            multiple
            :multiple-limit="4"
          >
            <!-- :disabled="
                fieldList.some((item) => item.fieldId === childitem.id)
              " -->
            <el-option
              v-for="(childitem, childIndex) in selectfield"
              :key="childIndex"
              :value="childitem.id"
              :label="childitem.labelName"
            ></el-option>
          </el-select>
        </el-form-item>
        <!--  选择按钮操作项-->
        <el-form-item
          :label="$t('vue_label_homepage_select_button_operation')"
          class="chooseeditbtn"
        >
          <!-- 请选择按钮操作项 -->
          <el-select
            v-model="CardruleForm.CardChoosebtn"
            filterable
            :placeholder="$t('vue_label_homepage_operationitem')"
            multiple
            :multiple-limit="2"
          >
            <template v-if="isShowBuns">
              <el-option
                v-for="item in operationSelection"
                :key="item.id"
                :value="item.id"
                :label="item.name"
                min-width="300"
              >
                <div class="dropDownBox">
                  <svg class="icon" aria-hidden="true">
                    <use :href="'#icon-' + item.svg"></use>
                  </svg>
                  <span>{{ item.name }}</span>
                </div>
              </el-option>
              <el-option
                v-for="item in operationSelections"
                :key="item.id"
                :value="item.id"
                :label="item.name"
                min-width="300"
              >
                <div class="dropDownBox">
                  <svg class="icon" aria-hidden="true">
                    <use :href="'#icon-' + item.svg"></use>
                  </svg>
                  <span>{{ item.name }}</span>
                </div>
              </el-option>
            </template>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <!-- 单个仪表板组件的选项面板 -->
    <div
      class="dashboard-bar"
      v-if="isDashboardEditToThis"
      v-loading="isLoadingEdit"
    >
      <el-form
        :model="DashboardCpnruleForm"
        :rules="DashboardCpnrules"
        ref="DashboardruleEditForm"
        label-width="140px"
        class="Dashboard-ruleFormDashboard-ruleForm"
      >
        <!-- 组件名称 -->
        <el-form-item
          :label="$t('homepage.components.name')"
          prop="DashboardName"
        >
          <el-input v-model="DashboardCpnruleForm.DashboardName"></el-input>
        </el-form-item>
        <!-- 选择仪表板 -->
        <el-form-item
          :label="$t('label.report.action.addtodashboard3')"
          prop="DashboardChooseID"
        >
          <!-- 请选择仪表板 -->
          <el-select
            v-model="DashboardCpnruleForm.DashboardChooseID"
            filterable
            reserve-keyword
            :placeholder="$t('label.select.dashboar.type')"
            @click.native="getDashboardList"
            @change="
              getDashboardList();
              clearDashboardruleForm();
            "
          >
            <el-option
              v-for="item in DashboardList"
              :key="item.id"
              reserve-keyword
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- 选择组件 -->
        <el-form-item
          :label="$t('vue_label_homepage_select_components')"
          prop="DashboardassemblyID"
        >
          <!-- 请选择组件 -->
          <el-select
            v-model="DashboardCpnruleForm.DashboardassemblyID"
            filterable
            reserve-keyword
            :placeholder="$t('vue_label_homepage_components')"
            @click.native="getAssemblyList"
            @change="getAssemblyList"
          >
            <el-option
              v-for="item in assemblyList"
              :key="item.id"
              reserve-keyword
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <!-- 仪表板面板选项面板 -->
    <!--    label-width="120px" -->
    <div class="dashboard-bar" v-if="isDashboardPagEditing">
      <el-form
        :model="DashboardruleForm"
        :rules="Dashboardrules"
        label-width="140px"
        ref="DashboardruleEditForm"
        class="Dashboard-ruleFormDashboard-ruleForm"
      >
        <!-- 组件名称 -->
        <el-form-item
          :label="$t('homepage.components.name')"
          prop="DashboardName"
        >
          <el-input v-model="DashboardruleForm.DashboardName"></el-input>
        </el-form-item>
        <!-- 选择仪表板 -->
        <el-form-item
          :label="$t('label.report.action.addtodashboard3')"
          prop="DashboardChooseID"
        >
          <!-- 请选择仪表板 -->
          <el-select
            v-model="DashboardruleForm.DashboardChooseID"
            filterable
            reserve-keyword
            :placeholder="$t('label.select.dashboar.type')"
            @click.native="getDashboardList"
            @change="
              getDashboardList();
              clearDashboardruleForm();
            "
          >
            <el-option
              v-for="item in DashboardList"
              :key="item.id"
              reserve-keyword
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <!-- 固定组件的选项面板 -->
    <div class="fixed-style" v-if="isFixedComponents">
      <el-form
        :model="fixedForm"
        :rules="fixedFormRules"
        ref="fixedComponentsRef"
        label-width="140px"
        class="Dashboard-ruleForm"
      >
        <!-- 选择组件 -->
        <el-form-item
          :label="$t('vue_label_homepage_select_components')"
          prop="fixedCpnName"
        >
          <!-- 请选择组件 -->
          <el-select
            v-model="fixedForm.fixedCpnId"
            filterable
            reserve-keyword
            :placeholder="$t('vue_label_homepage_components')"
            @change="clickFixed"
          >
            <el-option
              v-for="(item, index) in selectFixedField"
              :key="index"
              reserve-keyword
              :value="item.id"
              :label="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <!-- 自定义组件选项面板 -->
    <div class="dashboard-bar" v-if="isCustomComponents">
      <el-form
        :model="customForm"
        :rules="customFormRules"
        ref="customComponentsRef"
        label-width="140px"
        class="Dashboard-ruleForm"
      >
        <!-- 选择组件 -->
        <el-form-item
          :label="$t('label.globaloperation.operationtype.custom.page')"
          prop="customCpnName"
        >
          <!-- 请选择组件 -->
          <el-select
            v-model="customForm.customCpnName"
            filterable
            reserve-keyword
            :placeholder="$t('label.tabpage.pleaseSelectz')"
            @change="clickCustom"
          >
            <el-option
              v-for="(item, index) in selectCustomField"
              :key="index"
              reserve-keyword
              :value="item.pageApi"
              :label="item.pageLabel"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <!-- 编辑的tab切换结束 -->
    <!-- 非编辑状态的底部菜单 -->
    <span
      slot="footer"
      class="dialog-footer"
      v-if="
        !isEditToThis &&
        !isDashboardEditToThis &&
        !isFixedComponents &&
        !isDashboardPagEditing &&
        !isCustomComponents
      "
    >
      <el-button @click="assemblyCancel">
        <!-- 取 消 -->
        {{ $t("button_partner_cancel") }}
      </el-button>
      <el-button type="primary" @click="assemblySave">
        <!-- 保 存 -->
        {{ $t("label.save") }}
      </el-button>
    </span>
    <!-- 涉及到事件不一致 so 重写 -->
    <!-- 卡片视图组件编辑状态的底部菜单 -->
    <span slot="footer" class="dialog-footer" v-if="isEditToThis">
      <el-button @click="assemblyCancel">
        <!-- 取消 -->
        {{ $t("button_partner_cancel") }}
      </el-button>
      <el-button type="primary" @click="assemblyEditSave">
        <!-- 保存 -->
        {{ $t("label.save") }}
      </el-button>
    </span>
    <!-- 单个仪表板编辑状态的底部菜单 -->
    <span slot="footer" class="dialog-footer" v-if="isDashboardEditToThis">
      <el-button @click="assemblyCancel">
        <!-- 取消 -->
        {{ $t("button_partner_cancel") }}
      </el-button>
      <el-button type="primary" @click="assemblyDashboardEditSave">
        <!-- 保存 -->
        {{ $t("label.save") }}
      </el-button>
    </span>
    <!-- 仪表板页面编辑状态的底部菜单 -->
    <span slot="footer" class="dialog-footer" v-if="isDashboardPagEditing">
      <el-button @click="assemblyCancel">
        <!-- 取消 -->
        {{ $t("button_partner_cancel") }}
      </el-button>
      <el-button type="primary" @click="dashboardPageSave">
        <!-- 保存 -->
        {{ $t("label.save") }}
      </el-button>
    </span>
    <!-- 固定组件编辑状态的底部菜单 -->
    <span slot="footer" class="dialog-footer" v-if="isFixedComponents">
      <el-button @click="assemblyCancel">
        <!-- 取消 -->
        {{ $t("button_partner_cancel") }}
      </el-button>
      <el-button type="primary" @click="fixedComponentdEditSave">
        <!-- 保存 -->
        {{ $t("label.save") }}
      </el-button>
    </span>
    <!-- 自定义组件编辑状态的底部菜单 -->
    <span slot="footer" class="dialog-footer" v-if="isCustomComponents">
      <el-button @click="assemblyCancel">
        <!-- 取消 -->
        {{ $t("button_partner_cancel") }}
      </el-button>
      <el-button type="primary" @click="customComponentdEditSave">
        <!-- 保存 -->
        {{ $t("label.save") }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getobjectlist } from "../api.js";
import * as request from "../api.js";
import { getDashboardList, getChartsByDashboardId } from "../api.js";
export default {
  props: {
    isShowComPersonAddAssembly: {
      type: Boolean,
      default: false,
    },
    homePageObjectData: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    this.personalHomepage = this.homePageObjectData.id;
    this.$bus.$on("radio", this.ebFn);
  },
  data() {
    return {
      isShowBun: false, //带有新建按钮
      isShowBuns: true, //不带新建按钮
      // 固定组件保存的数据
      fixedNameData: "",

      // 选择对象时的数据
      objectData: [],
      // 点击跳转时需要的id
      jumpId: "",
      /**
       * 如果是点击组件的编辑进来的，
       * 则传递此参数为true，
       * 则通过ref执行clickdom
       */
      isEditToThis: false, //卡片视图的是否是编辑状态
      isDashboardEditToThis: false, //是否是单个仪表板组件点击的编辑
      isFixedComponents: false, //固定组件是否为编辑状态
      isDashboardPagEditing: false, //是否是仪表板页面点击的编辑
      isCustomComponents: false, //自定义组件是否为编辑状态
      // 个人主页ID
      personalHomepage: "",
      formName: null, //当前要提交的表单
      activeName: "first",
      objectId: "",
      // 仪表盘-选择组件
      assemblyList: [],
      //选择仪表板
      DashboardList: [],
      // 选择对象
      selectObjects: [],
      // 选择视图
      selectView: [],
      // 选择展示字段
      selectfield: [],
      // 选择操作按钮
      operationSelection: [
        {
          svg: "newTasknewTask",
          name: this.$i18n.t("label.task.new"), //新建任务
          id: 1,
          isNewTask: "true",
        },
        {
          svg: "newEvent",
          name: this.$i18n.t("label_chatter_newEvent"), //新建事件
          id: 2,
          isNewEvent: "true",
        },
        {
          svg: "editEventeditEvent",
          name: this.$i18n.t("label.weixin.detail.edit"), //编辑
          id: 3,
          isEdit: "true",
        },
      ],
      // 选择操作按钮
      operationSelections: [
        {
          svg: "editEventeditEvent",
          name: this.$i18n.t("label.weixin.detail.edit"), //编辑
          id: 3,
          isEdit: "true",
        },
      ],
      radio: "1", //tab切换顺序
      titleName: this.$i18n.t("label.dashboard"), //标题名字 仪表板
      fieldList: [
        { fieldId: [], plusImg: true, minusImg: false, check: false },
      ], //选择字段列表

      //单个仪表板组件表单
      DashboardCpnruleForm: {
        i: "", //非图表id，而是外层组件布局的 i
        DashboardName: "", //仪表板组件名字
        DashboardChooseID: "", //选择仪表板
        /**
         *  选择组件,
         * 图表id(只有在编辑时,使用此变量,通过此变量使用bus更新组件)
         */
        DashboardassemblyID: "", //编辑时使用此组件回显选择的组件
        DashboardassemblyType: "", // 图表类型
        dashboardEditId: "", //仪表板编辑时使用此id，保存时通过此变量发送到仪表板更新使用
      },
      // 仪表板面板表单
      DashboardruleForm: {
        i: "", //非图表id，而是外层组件布局的 i
        DashboardName: "", //仪表板组件名字
        DashboardChooseID: "", //选择仪表板
        /**
         *  选择组件,
         * 图表id(只有在编辑时,使用此变量,通过此变量使用bus更新组件)
         */
        DashboardassemblyID: "", //编辑时使用此组件回显选择的组件
        dashboardEditId: "", //仪表板编辑时使用此id，保存时通过此变量发送到仪表板更新使用
      },
      /**
       * -------------------------------------------------------
       * 验证规则
       * 仪表板组件表单必填项  规则rules
       * -------------------------------------------------------
       */
      DashboardCpnrules: {
        DashboardName: {
          required: true,
          message: this.$i18n.t("vue_label_dashboard_componentname"),
          trigger: "blur",
        },
        DashboardChooseID: {
          required: true,
          message: this.$i18n.t("vue_label_homepage_componentname"), //"请输入组件"
          trigger: "blur",
        },
        // 添加仪表板组件验证规则
        DashboardassemblyID: {
          required: true,
          message: this.$i18n.t("vue_label_homepage_componentname"), //"请输入组件"
          trigger: "blur",
        },
      },
      // -------------------------------------------------------
      // 仪表板面板表单必填项  规则rules
      Dashboardrules: {
        DashboardName: {
          required: true,
          message: this.$i18n.t("vue_label_homepage_componentname"), //请输入组件名称
          trigger: "blur",
        },
        DashboardChooseID: {
          required: true,
          message: this.$i18n.t("vue_label_homepage_componentname"), //请输入组件名称
          trigger: "blur",
        },
        // 添加仪表板组件验证规则
        DashboardassemblyID: {
          required: true,
          message: this.$i18n.t("vue_label_homepage_componentname"), //请输入组件名称
          trigger: "blur",
        },
      },
      // -------------------------------------------------------

      // 卡片视图表单
      CardruleForm: {
        i: "", //卡片视图组件id
        CardName: "", //卡片视图名字
        CardView: "", //卡片视图选择id（选择视图）
        CardObject: "", //卡片视图选择对象（选择对象）
        CardField: [], //卡片视图选择字段（选择展示字段）
        CardChoosebtn: [], //卡片视图选择按钮操作项（选择按钮操作项）
      },
      // 卡片视图表单必填项
      Cardrules: {
        CardName: {
          required: true,
          message: this.$i18n.t("vue_label_homepage_componentname"), //请输入组件名称
          trigger: "blur",
        },
        CardObject: {
          required: true,
          message: this.$i18n.t("vue_label_notice_pleaseselectobject"), //请选择组件对象
          trigger: "change",
        },
        //请选择组件视图
        CardView: {
          required: true,
          message: this.$i18n.t("vue_label_notice_pleaseselecview"),
          trigger: "blur",
        },
        CardField: {
          required: true,
          message: this.$i18n.t("vue_label_homepage_select_display_field"), //请选择展示字段
          trigger: "blur",
        },
      },
      // ViewruleForm: {
      //   ViewName: "", //嵌入视图选项名字
      //   ViewDesc: "", //嵌入视图描述
      //   ViewHeight: "", //嵌入视图高度
      //   ViewURL: "", //嵌入式图URL地址
      // },
      // Viewrules: {
      //   ViewName: {
      //     required: true,
      //     message: this.$i18n.t("vue_label_homepage_componentname"), //请输入组件名称
      //     trigger: "blur",
      //   },
      //   ViewURL: {
      //     required: true,
      //     message: "请输入正确的URL地址",
      //     trigger: "blur",
      //   },
      //   ViewHeight: {
      //     required: true,
      //     message: "请填写一个高度,已确认占格数量",
      //     trigger: "blur",
      //   },
      //   describe: { required: true },
      // },
      // 添加固定组件表单
      fixedForm: {
        fixedCpnName: "",
        fixedCpnId: "",
      },
      // 自定义组件表单
      customForm: {
        customCpnName: "",
      },
      // 自定义组件page列表
      selectCustomField: [],
      //自定义组件下拉选中的pageapi
      pageApi: "",

      // 组件字段(标准组件多语言key和id对应关系在src/views/homePage/components/standardComponents.js文件中，更改这里需要同步更改上面这个文件中的对应关系)
      selectFixedField: [
        {
          id: "brief",
          name: this.$i18n.t("label.brief"), //简报
          type: "Brief",
          w: 12,
          h: 3,
          minW: 6,
          minH: 3,
          relatedid: "brief",
        },
        {
          id: "jdyj",
          name: this.$i18n.t("vue_label_homepage_quarterlyperformance"), //季度业绩
          type: "Achievement",
          w: 8,
          h: 5,
          minW: 6,
          minH: 4,
          relatedid: "jdyj",
        },
        {
          id: "zjjl",
          name: this.$i18n.t("component_newrecords_label_title"), //最近记录
          type: "Record",
          w: 4,
          h: 5,
          minW: 4,
          minH: 5,
          relatedid: "zjjl",
        },
        {
          id: "spzx",
          name: this.$i18n.t("label.manageAll.title"), //审批中心
          type: "Audit",
          w: 12,
          h: 6,
          minW: 12,
          minH: 4,
          relatedid: "spzx",
        },
        {
          id: "xssj",
          name: this.$i18n.t("label.salescloud.salesdashboard"), //"销售数据",
          type: "SalesData",
          w: 12,
          h: 10,
          minW: 8,
          minH: 10,
          relatedid: "xssj",
        },
        {
          id: "todayevent",
          name: this.$i18n.t("label.myevent"), //我的事件
          type: "CardView",
          w: 6,
          h: 4,
          minW: 4,
          minH: 4,
          relatedid: "todayevent",
        },
        // 任务视图
        {
          id: "mytask",
          name: this.$i18n.t("vue_label_homepage_taskview"),
          type: "Mytask",
          w: 6,
          h: 4,
          minW: 4,
          minH: 4,
          relatedid: "mytask",
        },
        // 30天未联系的业务机会
        {
          id: "opp30",
          name: this.$i18n.t("vue_label_homepage_30daysnocontact"),
          type: "CardView",
          w: 6,
          h: 4,
          minW: 4,
          minH: 4,
          relatedid: "opp30",
          isDelete: "false",
          isEdit: "false",
          isNewEvent: "true",
          isNewTask: "true",
        },
        {
          id: "opplj",
          name: this.$i18n.t("label.productsales.oppor.approach"), //临近业务机会
          type: "CardView",
          w: 6,
          h: 4,
          minW: 4,
          minH: 4,
          relatedid: "opplj",
          isDelete: "false",
          isEdit: "false",
          isNewEvent: "false",
          isNewTask: "false",
        },
        {
          id: "oppoverdue",
          name: this.$i18n.t("label.productsales.oppor.overdue"), //已逾期的业务机会
          type: "CardView",
          w: 6,
          h: 4,
          minW: 4,
          minH: 4,
          relatedid: "oppoverdue",
          isDelete: "false",
          isEdit: "true",
          isNewEvent: "false",
          isNewTask: "false",
        },
        // 公告
        {
          id: "Bulletin",
          name: this.$i18n.t("label.callboard"), //公告
          type: "Bulletin",
          w: 8,
          h: 6,
          minW: 6,
          minH: 4,
          relatedid: "bulletin",
        }, // 渠道的解决时间
        {
          id: "Channel",
          name: "渠道的解决时间", //渠道的解决时间
          type: "Channel",
          w: 6,
          h: 4,
          minW: 4,
          minH: 4,
          relatedid: "channel",
        },
        {
          //客服个案统计图
          id: "CustomerService",
          name: "客服个案统计图", //客服个案统计图
          type: "CustomerService",
          w: 12,
          h: 7,
          minW: 12,
          minH: 7,
          relatedid: "customerService",
        },
        {
          //满意度评价
          id: "Satisfaction",
          name: "满意度评价", //满意度评价
          type: "Satisfaction",
          w: 6,
          h: 4,
          minW: 4,
          minH: 4,
          relatedid: "satisfaction",
        },
        // 同事圈
        {
          id: "tsquan",
          name: this.$i18n.t("label.version.chatter.cooperation"), //同事圈
          type: "tsquan",
          w: 8,
          h: 10,
          minW: 8,
          minH: 8,
          relatedid: "tsquan",
        },
      ],
      fixedFormRules: {
        fixedCpnName: [
          // 请选择固定组件对象
          {
            required: true,
            message: this.$i18n.t("vue_label_homepage_fixedcomponent"),
            trigger: "blur",
          },
        ],
      },
      // 自定义组件
      customFormRules: {
        customCpnName: [
          // 请选择自定义组件
          {
            required: true,
            message: "请选择自定义组件",
            trigger: "blur",
          },
        ],
      },
      isLoadingEdit: false, //编辑状态的表单loading状态
      isLoadingDashboardListStatus: false, //仪表板列表获取中loading
      isLoadingCardListStatus: false, //卡片列表加载中loading
    };
  },
  watch: {
    // 监听是否是编辑状态,是才请求所谓表单数据
    isEditToThis(status) {
      if (status) {
        // 清空仪表板组件表单
        this.DashboardruleForm.i = "";
        this.DashboardruleForm.DashboardName = "";
        this.DashboardruleForm.DashboardChooseID = "";
        this.DashboardruleForm.DashboardassemblyID = "";

        // 清空仪表板面板表单
        this.DashboardruleForm.i = "";
        this.DashboardruleForm.DashboardName = "";
        this.DashboardruleForm.DashboardChooseID = "";
        this.DashboardruleForm.DashboardassemblyID = "";

        this.clickObject();
      }
    },
    // 监听是否是单个仪表板编辑状态，是才请求所谓表单数据
    isDashboardEditToThis(status) {
      if (status) {
        this.getDashboardList();
        this.getAssemblyList();
      }
    },
    // 监听是否是仪表板页面编辑状态，是才请求所谓表单数据
    isDashboardPagEditing(status) {
      if (status) {
        this.getDashboardList();
        this.getAssemblyList();
      }
    },
    // 非编辑状态的tab切换
    radio(news) {
      if (news == "1") {
        //自定义组件选项隐藏
        this.isCustomComponents = false;

        // 仪表板组件
        this.titleName = this.$i18n.t(
          "vue_label_homepage_dashboard_components"
        );
        this.formName = this.$refs.DashboardruleForm;

        // 清空仪表板面板表单
        this.DashboardruleForm.i = "";
        this.DashboardruleForm.DashboardName = "";
        this.DashboardruleForm.DashboardChooseID = "";
        this.DashboardruleForm.DashboardassemblyID = "";

        // 清空仪表板组件表单
        this.DashboardCpnruleForm.i = "";
        this.DashboardCpnruleForm.DashboardName = "";
        this.DashboardCpnruleForm.DashboardChooseID = "";
        this.DashboardCpnruleForm.DashboardassemblyID = "";
      } else if (news == "2") {
        //自定义组件选项隐藏
        this.isCustomComponents = false;

        // 卡片视图
        this.titleName = this.$i18n.t("vue_label_homepage_card_view");
        this.formName = this.$refs.CardruleForm;
      } else if (news == "3") {
        //自定义组件选项隐藏
        this.isCustomComponents = false;

        // this.titleName = "嵌入页面";
      } else if (news == "4") {
        //自定义组件选项隐藏
        this.isCustomComponents = false;

        // 固定
        this.titleName = this.$i18n.t("vue_label_homepage_fixed_components");
        this.formName = this.$refs.fixedComponentsRef;
      } else if (news == "5") {
        //自定义组件选项隐藏
        this.isCustomComponents = false;

        // 仪表盘页面
        this.titleName = this.$i18n.t("vue_label_homepage_kanban");
        this.formName = this.$refs.instrumentForm;

        // 清空仪表板面板表单
        this.DashboardruleForm.i = "";
        this.DashboardruleForm.DashboardName = "";
        this.DashboardruleForm.DashboardChooseID = "";
        this.DashboardruleForm.DashboardassemblyID = "";

        // 清空仪表板组件表单
        this.DashboardCpnruleForm.i = "";
        this.DashboardCpnruleForm.DashboardName = "";
        this.DashboardCpnruleForm.DashboardChooseID = "";
        this.DashboardCpnruleForm.DashboardassemblyID = "";
      } else if (news == "6") {
        //自定义组件选项隐藏
        this.isCustomComponents = false;

        this.$bus.$emit("showComponentsEditorAlert");
      } else if (news == "7") {
        //自定义组件选项显示
        this.isCustomComponents = true;

        this.customForm.customCpnName = "";
        this.pageApi = "";

        // 请求下拉框数据
        let parameter1 = {
          condition: { pageLabel: "", dtBegin: "", dtEnd: "" },
          pageNo: 1,
          pageSize: 100000000,
        };
        this.$devHttp
          .postFormat(
            `${window.Glod['ccex-dev']}/custom/pc/1.0/post/pageCustomPage`,
            parameter1
          )
          .then((res) => {
            this.selectCustomField = res.data.list;
          });
      }
    },
    // 监听选择对象选项的id的变化，如果改变就进行请求获取展示字段列表进行回显操作
    objectId(val) {
      if (val !== "") {
        // 如果选择对象操作改变后，就会重新请求对象下的视图【编辑或新增时】
        this.clickView();
        this.displayField();
        // 编辑时会执行请求，获取展示字段的列表
        this.displayFieldIsEditSatus();
      }
    },
    // 只有项目云时才会有这两个组件
    homePageObjectData(val) {
      if (val.id === "20214152DFC0DEDW4LlP") {
        let arr = [
          {
            id: "gzlphb",
            name: this.$i18n.t(
              "front-homepageobject-module-v1-workload-ranking"
            ), //工作量排行榜
            type: "WorkloadRanking",
            w: 6,
            h: 6,
            minW: 6,
            minH: 4,
            relatedid: "gzlphb",
          },
          {
            id: "xmlbhgtt",
            name: this.$i18n.t("front-gantt-module-v1-project-gantt"), //项目甘特图
            type: "ProjectGantt",
            w: 12,
            h: 4,
            minW: 12,
            minH: 4,
            relatedid: "xmlbhgtt",
          },
        ];
        // 数组拼接
        this.selectFixedField = this.selectFixedField.concat(arr);
      }
    },
  },
  methods: {
    ebFn(){
      this.radio = "1";
      this.activeName = "first";
    },
    // 选择自定义组件api
    clickCustom(val) {
      this.pageApi = val;
      sessionStorage.setItem("pageapi", val);
    },

    clearDashboardruleForm() {
      this.DashboardCpnruleForm.DashboardassemblyID = "";
      this.DashboardruleForm.DashboardassemblyID = "";
    },
    // 获取仪表板列表
    async getDashboardList() {
      this.isLoadingDashboardListStatus = true;
      this.isLoadingEdit = true;
      let params = {
        dashboardfolderid: "",
        islightning: "true",
        name: "",
        page: "1",
        pageSize: "100000000",
      };
      const {
        data: { listDashboard },
      } = await getDashboardList(params);
      this.DashboardList = listDashboard;
      this.isLoadingEdit = false;
      this.isLoadingDashboardListStatus = false;
    },
    //  获取仪表板组件列表
    async getAssemblyList() {
      let params = {
        dashboardid: this.DashboardCpnruleForm.DashboardChooseID,
      };
      const {
        data: { charts },
      } = await getChartsByDashboardId(params);
      this.assemblyList = charts;
    },

    // 取消保存
    assemblyCancel() {
      this.$emit("assemblyCancel");
      // 让是编辑状态设为false
      this.isEditToThis = false;
      // 让单个仪表板编辑状态设置为false
      this.isDashboardEditToThis = false;
      // 让编辑状态的固定组件变为false
      this.isFixedComponents = false;
      // 让仪表板页面编辑状态为false
      this.isDashboardPagEditing = false;
      // // 让自定义组件页面编辑状态为false
      // this.isCustomComponents = false;
      // 清空表单选中数据
      this.clearFormDataFun();
      /**
       * 让选中状态初始化，开始
       */
      this.radio = "1";
      this.activeName = "first";
      /**
       * 让选中状态初始化，结束
       */
    },
    // 根据选择的图表id获取图表类型
    getChartType() {
      let chartData = this.assemblyList.find(
        (item) => item.id === this.DashboardCpnruleForm.DashboardassemblyID
      );
      if (chartData) {
        return chartData.dashboardtype;
      }
    },
    // 保存卡片视图或仪表板组件判断必选项是否填写
    assemblySave() {
      !this.formName
        ? (this.formName = this.$refs.DashboardruleForm)
        : this.formName;

      this.formName.validate((valid) => {
        if (valid) {
          if (this.radio == "1") {
            // 添加图表组件：仪表板中的图表、直接添加图表
            this.$emit("saveDashboardCpn", {
              name: this.DashboardCpnruleForm.DashboardName,
              DashboardChooseID: this.DashboardCpnruleForm.DashboardChooseID,
              DashboardassemblyID:
                this.DashboardCpnruleForm.DashboardassemblyID,
              DashboardassemblyType: this.getChartType(), // 图表类型
            });
          } else if (this.radio == "2") {
            // 添加卡片视图
            let CardruleForm = JSON.parse(JSON.stringify(this.CardruleForm));
            CardruleForm.CardChoosebtn = this.operationSelection.filter(
              (item) => CardruleForm.CardChoosebtn.indexOf(item.id) > -1
            );
            CardruleForm.CardField = CardruleForm.CardField.join(",");
            this.$emit("assemblySave", CardruleForm);
          } else if (this.radio == "4") {
            // 添加固定组件
            // let fixedNameData = []
            this.fixedNameData = this.selectFixedField.filter((item) => {
              if (item.id === this.fixedName) {
                return item;
              }
            });
            this.$emit("fixedComponentdDataSave", this.fixedNameData);
          } else if (this.radio == "5") {
            // 添加仪表板面板
            this.$emit("instrumentClusterSave", {
              name: this.DashboardruleForm.DashboardName,
              DashboardChooseID: this.DashboardruleForm.DashboardChooseID,
              DashboardassemblyID: this.DashboardruleForm.DashboardassemblyID,
            });
          }

          // 让是编辑状态设为false
          this.isEditToThis = false;
          // 让仪表板编辑状态设置为false
          this.isDashboardEditToThis = false;
          // 让编辑状态的固定组件变为false
          this.isFixedComponents = false;
          // 让仪表板页面编辑状态为false
          this.isDashboardPagEditing = false;

          // 清空表单选中数据
          this.clearFormDataFun();
          /**
           * 让选中状态初始化，开始
           */
          this.radio = "1";
          this.activeName = "first";
          /**
           * 让选中状态初始化，结束
           */
        } else {
          return false;
        }
      });
    },

    // 编辑卡片视图保存事件发射
    assemblyEditSave() {
      this.$refs.CardruleForm.validate((valid) => {
        if (valid) {
          // 添加卡片视图
          let CardruleForm = JSON.parse(JSON.stringify(this.CardruleForm));
          CardruleForm.CardChoosebtn = this.operationSelection.filter(
            (item) => CardruleForm.CardChoosebtn.indexOf(item.id) > -1
          );
          //  选择展示字段数组数据
          CardruleForm.CardField = CardruleForm.CardField.join(",");
          //  编辑卡片视图保存事件发射到index
          this.$emit("cardCpnEditSave", CardruleForm);

          // 让是编辑状态设为false
          this.isEditToThis = false;
          // 异步关闭，防止闪现
          setTimeout(() => {
            // 清空卡片视图表单内容
            this.CardruleForm.i = "";
            this.CardruleForm.CardName = "";
            this.CardruleForm.CardView = "";
            this.CardruleForm.CardObject = "";
            this.CardruleForm.CardField = [];
            this.CardruleForm.CardChoosebtn = [];
          });
        } else {
          return false;
        }
      });
    },
    // 仪表板编辑保存事件发射
    assemblyDashboardEditSave() {
      this.$refs.DashboardruleEditForm.validate((valid) => {
        if (valid) {
          // 编辑仪表板组件，发送到index页面发送请求
          this.$emit("assemblyDashboardEditSave", {
            i: this.DashboardCpnruleForm.i, //非图表id，而是外层组件布局的 i
            name: this.DashboardCpnruleForm.DashboardName,
            DashboardChooseID: this.DashboardCpnruleForm.DashboardChooseID,
            DashboardassemblyID: this.DashboardCpnruleForm.DashboardassemblyID,
            dashboardEditId: this.DashboardCpnruleForm.dashboardEditId,
            DashboardassemblyType: this.getChartType(), // 图表类型
          });
        } else {
          return false;
        }
        // 让是编辑状态设为false
        this.isEditToThis = false;
        // 让仪表板编辑状态设置为false
        this.isDashboardEditToThis = false;
        // 让编辑状态的固定组件变为false
        this.isFixedComponents = false;
        // 让仪表板页面编辑状态为false
        (this.isDashboardPagEditing = false),
          // 清空卡片视图表单内容
          (this.CardruleForm.i = "");
        this.CardruleForm.CardName = "";
        this.CardruleForm.CardView = "";
        this.CardruleForm.CardObject = "";
        this.CardruleForm.CardField = [];
        this.CardruleForm.CardChoosebtn = [];
      });
    },
    // 编辑仪表板面板保存发射事件
    dashboardPageSave() {
      this.$refs.DashboardruleEditForm.validate((valid) => {
        if (valid) {
          // 编辑仪表板组件，发送到index页面发送请求
          // this.$emit("saveDashboardCpn", {
          //   i: this.DashboardruleForm.i, //非图表id，而是外层组件布局的 i
          //   name: this.DashboardruleForm.DashboardName,
          //   DashboardChooseID: this.DashboardruleForm.DashboardChooseID,
          //   DashboardassemblyID: this.DashboardruleForm.DashboardassemblyID,
          // });
          this.$emit("dashboardPageSave", {
            i: this.DashboardruleForm.i, //非图表id，而是外层组件布局的 i
            name: this.DashboardruleForm.DashboardName,
            DashboardChooseID: this.DashboardruleForm.DashboardChooseID,
            DashboardassemblyID: this.DashboardruleForm.DashboardassemblyID,
          });
        } else {
          return false;
        }
        // 让是编辑状态设为false
        this.isEditToThis = false;
        // 让仪表板编辑状态设置为false
        this.isDashboardEditToThis = false;
        // 让编辑状态的固定组件变为false
        this.isFixedComponents = false;
        // 让仪表板页面编辑状态为false
        (this.isDashboardPagEditing = false),
          // 清空卡片视图表单内容
          (this.CardruleForm.i = "");
        this.CardruleForm.CardName = "";
        this.CardruleForm.CardView = "";
        this.CardruleForm.CardObject = "";
        this.CardruleForm.CardField = [];
        this.CardruleForm.CardChoosebtn = [];
      });
    },
    // 编辑固定组件发射事件
    fixedComponentdEditSave() {
      this.$refs.fixedComponentsRef.validate((valid) => {
        if (valid) {
          let fixedValue = this.selectFixedField.filter((item) => {
            if (item.id === this.fixedName) {
              return item;
            }
          });
          this.$emit("editPinnedComponents", fixedValue);
          // 让编辑状态的固定组件变为false
          this.isFixedComponents = false;
          // 清空固定组件form表单
          this.fixedForm.fixedCpnName = "";
        }
      });
    },
    //编辑自定义组件发射事件
    customComponentdEditSave() {
      this.$refs.customComponentsRef.validate(() => {
        if (this.pageApi == sessionStorage.getItem("pageapi")) {
          this.$emit("customComponentdEdit");
          this.$emit("assemblyCancel");
          this.customForm.customCpnName = "";
          this.pageApi = "";
        }
      });

      // 
    },
    // 点击空白关闭弹窗事件
    handleClose() {
      this.$emit("assemblyCancel");
      /**
       * 让选中状态初始化，开始
       */
      this.radio = "1";
      this.activeName = "first";
      /**
       * 让选中状态初始化，结束
       */
      // 让是编辑状态设为false
      this.isEditToThis = false;
      // 让仪表板编辑状态设置为false
      this.isDashboardEditToThis = false;
      // 让编辑状态的固定组件变为false
      this.isFixedComponents = false;
      // 让仪表板页面编辑状态为false
      this.isDashboardPagEditing = false;
      // 清空表单选中数据
      this.clearFormDataFun();
    },

    // 选择对象(获取选择对象数据)
    async clickObject(itemId) {
      this.jumpId = itemId;
      let params1 = {
        searchKeyWord: "",
      };
      let dataObject = await getobjectlist(params1);
      this.objectData = dataObject.data;
      this.selectObjects = this.objectData;
      this.objectId = this.CardruleForm.CardObject;
      if (this.objectId === "event" || this.objectId === "task") {
        this.isShowBuns = false;
        this.isShowBun = true;
      } else {
        this.isShowBun = false;
        this.isShowBuns = true;
      }
      // this.clickView();
    },
    // 选择视图
    async clickView() {
      let dataView = await request.getviewlist({ objId: this.objectId });
      let dataViewData = dataView.data;
      this.selectView = dataViewData;
    },
    // 选择字段
    async displayField() {
      let fieldData = await request.getFieldList({ objId: this.objectId });
      // 展示字段数据
      let displayFieldData = fieldData.data;
      this.selectfield = displayFieldData;
      //

      var addNameField = this.selectfield.find((item) => {
        return item.api === "name";
      });
      if (
        addNameField &&
        !this.isEditToThis &&
        !this.isDashboardEditToThis &&
        !this.isFixedComponents &&
        !this.isDashboardPagEditing
      ) {
        //新增卡片视图时，默认选中 name字段，
        this.CardruleForm.CardField = [];
        this.CardruleForm.CardField.push(addNameField.id);
      }
    },
    // 编辑状态时，会使用此方法进行列表的请求达到通过返回的id进行回显效果
    async displayFieldIsEditSatus() {
      this.isLoadingCardListStatus = true;
      this.isLoadingEdit = true;
      let fieldData = await request.getFieldList({ objId: this.objectId });
      this.isLoadingEdit = false;
      this.isLoadingCardListStatus = false;
      let displayFieldData = fieldData.data;
      this.selectfield = displayFieldData;
      // var addNameField = this.selectfield.find((item) => {
      //   return item.api === "name";
      // });
      // if (addNameField && this.isEditToThis) {
      //   //新增卡片视图时，默认选中 name字段，
      //   this.CardruleForm.CardField = [];
      //   this.CardruleForm.CardField.push(addNameField.id);
      // }
    },

    // 点击固定组件
    clickFixed(val) {
      this.fixedName = val;
    },
    // 清空选中的数据(当重新选择对象之后,把他下面的已选择视图和字段清空)
    clearSelectData() {
      this.CardruleForm.CardView = "";
      this.CardruleForm.CardField = [];

      // 每次清空选项中已选中的数据,并重新调用可选下拉菜单列表
      this.clickObject();
    },
    // 清空表单选中数据
    clearFormDataFun() {
      // 清空卡片视图表单内容
      this.CardruleForm.i = "";
      this.CardruleForm.CardName = "";
      this.CardruleForm.CardView = "";
      this.CardruleForm.CardObject = "";
      this.CardruleForm.CardField = [];
      this.CardruleForm.CardChoosebtn = [];
      // 清空仪表板组件表单
      this.DashboardCpnruleForm.i = "";
      this.DashboardCpnruleForm.DashboardName = "";
      this.DashboardCpnruleForm.DashboardChooseID = "";
      this.DashboardCpnruleForm.DashboardassemblyID = "";
      // 清空仪表板面板表单
      this.DashboardruleForm.i = "";
      this.DashboardruleForm.DashboardName = "";
      this.DashboardruleForm.DashboardChooseID = "";
      this.DashboardruleForm.DashboardassemblyID = "";
      // 清空固定组件form表单
      this.fixedForm.fixedCpnName = "";
    },
  },
  computed: {
    // dialog的添加组件弹窗的名称
    dialogTitleName() {
      if (
        !this.isEditToThis &&
        !this.isDashboardEditToThis &&
        !this.isFixedComponents &&
        !this.isDashboardPagEditing
      ) {
        // 添加组件
        // 中文: 添加{titleName}组件
        // this.$i18n.t("vue.add.dashboard.component",titleName:this.titleName);
        // `添加${this.titleName}组件`
        return this.$i18n.t("vue.add.dashboard.component", {
          titleName: this.titleName,
        });
      } else if (this.isEditToThis) {
        // 编辑卡片视图
        return this.$i18n.t("vue_label_homepage_edit_card_view");
      } else if (this.isDashboardEditToThis) {
        return this.$i18n.t("vue_label_homepage_dashboardcomponents");
      } else if (this.isFixedComponents) {
        return this.$i18n.t("vue_label_homepage_edit_fixed_components");
      } else if (this.isDashboardPagEditing) {
        return this.$i18n.t("vue_label_homepage_editkanban");
      }else{
        return ''
      }
    },
  },
  beforeDestroy(){
    this.$bus.$off("radio", this.ebFn);
  }
};
</script><style scoped lang="scss">
.Dashboard-ruleFormDashboard-ruleForm {
  ::v-deep .el-input__inner {
    width: 340px;
  }
}
//tab切换样式
::v-deep .el-tabs__item {
  height: 100%;
  line-height: 100%;
  padding: 0;
}
::v-deep
  .el-tabs__nav-wrap
  ::v-deep
  .el-tabs__nav-scroll
  ::v-deep
  .el-tabs__nav {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
::v-deep .el-tabs__active-bar {
  height: 0;
}
::v-deep .el-dialog__headerbtn {
  top: 17px;
}

::v-deep .el-tabs__nav-wrap::after {
  width: 0;
}
::v-deep .el-form-item {
  margin-bottom: 5px;
}
::v-deep .el-form-item__error {
  padding-top: 0;
}
::v-deep .el-input__inner {
  // width: 380px;
  width: 340px;
  height: 28px;
}
.dropDownBox {
  img,
  svg {
    margin-right: 5px;
    width: 16px;
    height: 16px;
  }
  // span {
  //   font-size: 12px;
  //   vertical-align: middle;
  // }
}

// 仪表板样式
.Dashboard-ruleForm {
  min-height: 200px;
}
// 嵌入页面样式
.page-ruleForm {
  min-height: 210px;
}
// 卡片视图样式
.card-ruleForm {
  ::v-deep .el-input__inner {
    // width: 380px;
    width: 340px;
    height: 28px;
  }
  .img-plus {
    margin-left: 24px;
  }
  .chooseeditbtn {
    // ::v-deep .el-input__inner,
    // .el-input {
    //   height: 28px !important;
    // }
    margin-top: 15px;
  }

  .chooseFiled {
    margin-top: 10px;

    .el-form-item__content {
      margin-left: 0;
    }
    // ::v-deep .el-select {
    //   margin-bottom: 20px;
    // }
    .cardCheck {
      margin: 0 10px;
      font-size: 12px;
      vertical-align: middle;
    }
    img {
      width: 14px;
      height: 14px;
      margin-right: 10px;
    }

    ::v-deep .el-input {
      // width: 330px;
      margin-right: 10px;
      // width: 380px;
      width: 340px;
    }
    ::v-deep .el-select {
      float: left;
    }
    ::v-deep .el-input__inner {
      // width: 330px;
      // width: 380px;
      width: 340px;
    }
    ::v-deep
      .el-select
      .el-tag.el-tag--info.el-tag--small.el-tag--light:nth-child(1)
      .el-tag__close.el-icon-close {
      display: none !important;
      //  background-color: red !important;
    }
  }
}
::v-deep .el-form-item__content {
  margin-top: 5px;
}
::v-deep .el-tag.el-tag--info {
  color: #1b5297;
  font-weight: bold;
}
::v-deep .el-icon-arrow-up::before {
  font-size: 12px;
}
::v-deep .el-tabs__nav-wrap {
  display: flex;
  justify-content: space-around;
}
::v-deep .el-radio__label {
  padding-left: 0;
  margin-right: 20px;
}
::v-deep .el-form-item__label {
  word-break: normal;
}
</style>

<style lang="scss">
.el-select-dropdown {
  max-width: 40%;
}
.el-select-dropdown__item {
  overflow: visible;
}
</style>

<style>
/* 隐藏下拉选择多选的对号显示 */
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected::after {
  content: "" !important;
}
</style>
