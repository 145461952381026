<!--
  个人主页组件删除弹框
-->
<template>
  <el-dialog
    :visible="dialogDelectTo"
    :title="$t('vue_label_norm_removecomponent')"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <div class="dialog-body">
      <div>
        <!-- <p>确认删除组件"{{ nameCpn }}"？</p> -->
        <p>
          {{ $t("vue_label_norm_removecomponent_certify") }}"{{ nameCpns }}"？
        </p>
        <!-- <p>此项操作不可撤回</p> -->
        <p>{{ $t("vue_label_norm_undone") }}</p>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="ComponentsDelCancle">
        <!-- 取消 -->
        {{ $t("button_partner_cancel") }}
      </el-button>
      <el-button type="primary" @click="ComponentsDelConfirm">
        <!-- 确定 -->
        {{ $t("label.ems.confirm") }}</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    nameCpn: {
      type: String,
      default: "",
    },
    relatedidCpn: {
      // 要删除的组件id
      type: String,
      default: "",
    },
    dialogDelectTo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // 标准组件信息
      selectFixedField: {
        brief: this.$i18n.t("label.brief"), //简报
        jdyj: this.$i18n.t("vue_label_homepage_quarterlyperformance"),
        zjjl: this.$i18n.t("component_newrecords_label_title"),
        spzx: this.$i18n.t("label.manageAll.title"),
        xssj: this.$i18n.t("label.salescloud.salesdashboard"),
        todayevent: this.$i18n.t("label.myevent"),
        mytask: this.$i18n.t("vue_label_homepage_taskview"),
        opp30: this.$i18n.t("vue_label_homepage_30daysnocontact"),
        opplj: this.$i18n.t("label.productsales.oppor.approach"),
        oppoverdue: this.$i18n.t("label.productsales.oppor.overdue"),
      },
    };
  },
  computed: {
    // 标准组件名称需要多语言，其他类型的组件不需要。所以根据id查找对应的label。
    nameCpns: function () {
      if (this.relatedidCpn && this.selectFixedField[this.relatedidCpn]) {
        return this.selectFixedField[this.relatedidCpn];
      }
      return this.nameCpn;
    },
  },
  methods: {
    handleClose() {
      this.$emit("ComponentsDelCancle");
    },
    //取消删除
    ComponentsDelCancle() {
      this.$emit("ComponentsDelCancle");
    },
    //确认删除
    ComponentsDelConfirm() {
      this.$emit("ComponentsDelConfirm");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  width: 552px;
  // height: 390px;
}
.dialog-body {
  div {
    p:first-child {
      font-size: 16px;
      color: #686868;
    }
    p:last-child {
      color: #ff3c32;
      font-size: 12px;
    }
  }
}
</style>